import Headings from '@narative/gatsby-theme-novela/src/components/Headings';
import Layout from '@narative/gatsby-theme-novela/src/components/Layout';
import MDX from '@narative/gatsby-theme-novela/src/components/MDX';
import Section from '@narative/gatsby-theme-novela/src/components/Section';
import SEO from '@narative/gatsby-theme-novela/src/components/SEO';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import theme from '../gatsby-plugin-theme-ui/index.js';

const releaseNotes = graphql`
  {
    mdx(slug: { eq: "release-notes" }) {
      slug
      headings {
        value
      }
      frontmatter {
        title
        date
        excerpt
        category
      }
      rawBody
      body
    }
  }
`;

export default function ReleaseNotesPage() {
  const {
    mdx: {
      frontmatter: { excerpt },
      body
    }
  } = useStaticQuery(releaseNotes);

  return (
    <Layout>
      <SEO />

      <Section>
        <div style={{ marginTop: '100px', marginBottom: '70px' }}>
          <Headings.h1>Release notes</Headings.h1>
          <Headings.h4 style={{ color: theme.colors.grey }}>
            {excerpt}
          </Headings.h4>
        </div>

        <MDX content={body}></MDX>
      </Section>
    </Layout>
  );
}
